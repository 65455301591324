<template>
	<div class="var-elevation--2 p-3 mb-3">
		<div class="d-flex justify-content-between">
			<h3>{{ name }}</h3>
			<var-button v-if="onCreateClick" type="primary" @click="onCreateClick">{{ buttonName }}</var-button>
		</div>
	</div>
</template>

<script>
export default {
	name: "PageHeader",
	props: {
		name: {
			type: String,
			required: true
		},
		subTitle: {
			type: String,
			required: false
		},
		buttonName: {
			type: String,
			required: false,
			default: 'Create'
		},
		onCreateClick: {
			type: Function,
			required: false
		}
	}
}
</script>

<style scoped>

.var-elevation--2 {
	background-color: var(--table-background);
}

.var-button--primary {
	background-color: var(--button-primary-color) !important;
}

h3 {
	line-height: unset;
	margin: 0;
}

</style>